import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { useCallback } from "react";

import { useFirebaseContext } from "contexts/FirebaseContext";
import { usePitPortClient } from "hooks/api/usePitPortClient";
import { convertToPublicURL, generateRandomFileName } from "utils/imageFileUtil";

export const initSpace = {
  name: "",
  width: 0,
  length: 0,
  height: null,
  "2tShort": true, // true：駐車可能、false：駐車不可能
  status: 1, // 0：利用不可、1：利用可
  type: 0, // 0:一般、1:限定
  contId: null,
  standId: null,
};

export const initPriceStructure = {
  weekday: {
    timeSlot1: {
      startHour: "",
      startMinute: "",
      endHour: "",
      endMinute: "",
      price: null,
      maxPrice: null,
    },
    timeSlot2: {
      startHour: "",
      startMinute: "",
      endHour: "",
      endMinute: "",
      price: null,
      maxPrice: null,
    },
    maxDurationHours: null,
    maxPricePerDuration: null,
  },
  dayOff: {
    timeSlot1: {
      startHour: "",
      startMinute: "",
      endHour: "",
      endMinute: "",
      price: null,
      maxPrice: null,
    },
    timeSlot2: {
      startHour: "",
      startMinute: "",
      endHour: "",
      endMinute: "",
      price: null,
      maxPrice: null,
    },
    maxDurationHours: null,
    maxPricePerDuration: null,
  },
  holiday: {
    timeSlot1: {
      startHour: "",
      startMinute: "",
      endHour: "",
      endMinute: "",
      price: null,
      maxPrice: null,
    },
    timeSlot2: {
      startHour: "",
      startMinute: "",
      endHour: "",
      endMinute: "",
      price: null,
      maxPrice: null,
    },
    maxDurationHours: null,
    maxPricePerDuration: null,
  },
};

/**
 * 署名付きURLで画像をアップロードする
 */
const uploadImagesBySignedURL = async (
  images: File[],
  client: (config: AxiosRequestConfig) => Promise<AxiosResponse>
): Promise<string[]> => {
  return Promise.all(
    images.map(async (image) => {
      const fileName = `property/${generateRandomFileName(image)}`;
      const response = await client({
        method: "post",
        url: `/property/images/signed-url/generate`,
        data: {
          fileType: image.type,
          fileName: fileName,
        },
      }).catch((e) => {
        throw e;
      });
      const url = response.data;
      const options = {
        headers: {
          "Content-Type": image.type,
          "Access-Control-Allow-Origin": "*",
        },
      };
      await axios.put(url, image, options).catch((e) => {
        console.error(e);
      });
      return convertToPublicURL(fileName);
    })
  ).catch((e) => {
    throw e;
  });
};

export const useRemoveProperty = () => {
  const { client } = usePitPortClient();

  const remove = useCallback(
    async (propertyId: number) => {
      const { status } = await client({
        method: "put",
        url: `/property/${propertyId}/status`,
        data: { status: 5 },
      });
      return status;
    },
    [client]
  );
  return { remove };
};

type PropertyErrorResponse = {
  status: number;
  data: {
    message: string;
  };
};

export const useUpdateProperty = () => {
  const { auth } = useFirebaseContext();
  const { client } = usePitPortClient();

  const update = useCallback(
    async (unUploadedImages: File[], updateProperty: UpdateProperty) => {
      const token = await auth?.currentUser?.getIdToken();
      if (!token) return { status: 401 };

      const uploadImageURLs = await uploadImagesBySignedURL(unUploadedImages, client);
      const uploadImages = uploadImageURLs.map((imageURL) => ({
        url: imageURL,
      }));

      const images = [...updateProperty.images, ...uploadImages];

      const spaces = updateProperty.spaces.map((space) => ({
        id: space.id ?? undefined,
        name: space.name ?? "",
        width: space.width,
        length: space.length,
        height: space.height === 0 ? null : space.height,
        "2tShort": space["2tShort"],
        status: space.status === 0 ? 0 : 1,
        type: space.type === 0 ? 0 : 1,
        standId: space.standId ? space.standId : null,
        contId: space.contId ? space.contId : null,
      }));

      const { status, data }: PropertyErrorResponse = await client({
        method: "put",
        url: `/property/${updateProperty.id}`,
        data: {
          ...updateProperty,
          spaces,
          images,
          priceStructureState: updateProperty.priceStructureState,
        },
      });

      return { status, message: data.message };
    },
    [client, auth?.currentUser]
  );

  return { update };
};

export const useCreateProperty = () => {
  const { auth } = useFirebaseContext();
  const { client } = usePitPortClient();

  const create = useCallback(
    async (images: File[], property: CreateProperty) => {
      const token = await auth?.currentUser?.getIdToken();
      if (!token) return false;
      const imageURLs = await uploadImagesBySignedURL(images, client);
      const spaces = property.spaces.map((space) => {
        return {
          name: space.name ?? "",
          width: space.width,
          length: space.length,
          height: space.height === 0 ? null : space.height,
          "2tShort": space["2tShort"],
          status: space.status === 0 ? 0 : 1,
          type: space.type === 0 ? 0 : 1,
          standId: space.standId ? space.standId : null,
          contId: space.contId ? space.contId : null,
          ownerEntityId: property.ownerEntityId ?? 1, // landit
        };
      });
      const { status } = await client({
        method: "post",
        url: `/property`,
        data: {
          ...property,
          images: imageURLs.map((imageURL) => ({
            url: imageURL,
          })),
          spaces,
        },
      });
      return status;
    },
    [client, auth?.currentUser]
  );
  return { create };
};
