import styled from "styled-components";

import TextForm from "components/TextForm";
import color from "constants/color";

const BorderContainer = styled.div`
  display: flex;
  border-right: 1px solid ${color.border};
`;

const Label = styled.p`
  margin-bottom: 10px;
  font-size: 14px;
`;

const FlexCenter = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
`;

const MarginRight = styled.div`
  margin-right: 10px;
`;

const MarginRightFive = styled.div`
  margin-right: 5px;
`;

const SpanWithMargin = styled.span`
  margin-left: 5px;
  margin-right: 5px;
`;

const RequiredBadge = styled.span`
  font-size: 10px;
  background-color: #ee8f00;
  color: #fff;
  padding: 2px 4px;
  height: 22px;
  font-family: "Noto Sans JP", sans-serif;
  font-weight: 400;
`;

interface Props {
  onChange: (field: string, e: React.ChangeEvent<HTMLInputElement>) => void;
  priceStructure: PriceStructureData;
  fieldPrefix: "timeSlot1" | "timeSlot2";
  errors?: {
    startHour?: boolean;
    startMinute?: boolean;
    price?: boolean;
  };
}

const TimePriceBlock = ({ onChange, priceStructure, fieldPrefix, errors }: Props) => {
  const timeSlot: TimeSlotData = priceStructure[fieldPrefix];
  return (
    <BorderContainer>
      <div>
        <div style={{ marginBottom: "10px", alignItems: "center" }}>
          <span style={{ fontSize: "14px", marginRight: "2px" }}>開始時間</span>
          {fieldPrefix === "timeSlot1" && <RequiredBadge>必須</RequiredBadge>}
        </div>
        <FlexCenter>
          <TextForm
            type="text"
            width="50px"
            value={timeSlot.startHour}
            placeholder=""
            required
            onChange={(e) => onChange(`${fieldPrefix}_startHour`, e)}
            isError={fieldPrefix === "timeSlot1" ? errors?.startHour && errors?.startMinute : false}
            errorMessage="時間を入力してください"
          />
          <SpanWithMargin>:</SpanWithMargin>
          <TextForm
            type="text"
            width="50px"
            value={timeSlot.startMinute}
            placeholder=""
            required
            onChange={(e) => onChange(`${fieldPrefix}_startMinute`, e)}
            isError={false}
            errorMessage=""
          />
        </FlexCenter>
      </div>
      <MarginRight>
        <div style={{ marginBottom: "10px", alignItems: "center" }}>
          <span style={{ fontSize: "14px" }}>時間料金</span>
          <span style={{ fontSize: "10px" }}>（15分）</span>
          {fieldPrefix === "timeSlot1" && <RequiredBadge>必須</RequiredBadge>}
        </div>
        <FlexCenter>
          <TextForm
            type="number"
            width="80px"
            value={timeSlot.price}
            placeholder=""
            required
            onChange={(e) => onChange(`${fieldPrefix}_price`, e)}
            isError={
              fieldPrefix === "timeSlot1"
                ? errors?.price
                : timeSlot.startHour !== "" && timeSlot.startMinute !== "" && timeSlot.price === null
            }
            errorMessage={
              fieldPrefix === "timeSlot1"
                ? "料金を入力してください"
                : timeSlot.startHour !== "" && timeSlot.startMinute !== "" && timeSlot.price === null
                ? "時間帯を入力した場合、時間料金も入力してください"
                : ""
            }
            disabled={fieldPrefix === "timeSlot2" && (timeSlot.startHour === "" || timeSlot.startMinute === "")}
          />
          <MarginRightFive />
          <span>円</span>
        </FlexCenter>
      </MarginRight>
      <MarginRight>
        <Label>時間帯最大料金</Label>
        <FlexCenter>
          <TextForm
            type="number"
            width="90px"
            value={timeSlot.maxPrice}
            placeholder=""
            required
            onChange={(e) => onChange(`${fieldPrefix}_maxPrice`, e)}
            isError={false}
            errorMessage=""
            disabled={
              fieldPrefix === "timeSlot2" &&
              (timeSlot.startHour === "" || timeSlot.startMinute === "" || timeSlot.price === null)
            }
          />
          <MarginRightFive />
          <span>円</span>
        </FlexCenter>
      </MarginRight>
    </BorderContainer>
  );
};

export default TimePriceBlock;
