import dayjs from "dayjs";
import { z } from "zod";

const AddressSchema = z
  .string()
  .refine((val) => !/^\d{3}-\d{4}/.test(val), { message: "住所の先頭に郵便番号を含めないでください" })
  .refine((val) => !/[０-９]/.test(val), { message: "住所には半角数字を使用してください" })
  .refine((val) => !/^-/.test(val), { message: "住所の先頭にハイフンを含めないでください" })
  .refine((val) => !/ｰ/.test(val), {
    message: "住所には半角ハイフン（-）を使用してください",
  })
  .refine((val) => !/[^\d\s]-[^\d\s]/.test(val), { message: "住所のテキスト部分にハイフンを含めないでください" })
  .refine((val) => /^[^\d\s]+\d+(?:-\d+){0,2}$/.test(val), {
    message: "住所のフォーマットが正しくありません 例）東京都港区三田1-2-3",
  });

const positiveNumber = (field: string, min = 0) =>
  z.coerce
    .number({
      invalid_type_error: `${field}はカンマを含めない数値のみで入力してください`,
    })
    .min(min, {
      message: `${field}は${min}以上の数値を入力してください`,
    })
    .int({ message: `${field}は整数のみで入力してください` });

const enumWithMessage = <T extends readonly [string, ...string[]]>(values: T, field: string) =>
  z.enum(values, {
    errorMap: () => ({
      message: `${field}は ${values.map((v) => `'${v}'`).join(", ")} のいずれかを指定してください`,
    }),
  });

const PropertyCsvSchema = z
  .object({
    駐車場名: z.string().min(1, { message: "駐車場名は必須です" }).max(255, { message: "駐車場名は255文字までです" }),
    住所: AddressSchema,
    "物件No（管理ナンバー）": z.string().min(1, { message: "物件Noは必須です" }),
    駐車場の備考: z.string().optional(),
    担当者: z.string().uuid({ message: "担当者はユーザーID（UUID）を入力してください" }),
    賃料: positiveNumber("賃料"),
    "賃料 課税 / 非課税": enumWithMessage(["課税", "非課税"], "賃料 課税 / 非課税"),
    その他月額賃料: positiveNumber("その他月額賃料"),
    仲介手数料: positiveNumber("仲介手数料"),
    "敷金（返却あり）": positiveNumber("敷金（返却あり）"),
    "敷金（返却あり）課税 / 非課税": enumWithMessage(["課税", "非課税"], "敷金（返却あり）課税 / 非課税"),
    "礼金（返却あり）": positiveNumber("礼金（返却あり）"),
    // ※ここはテンプレートヘッダーに合わせ、「礼金  課税 / 非課税」とする（半角スペース2個）
    "礼金  課税 / 非課税": enumWithMessage(["課税", "非課税"], "礼金  課税 / 非課税").optional(),
    その他費用: positiveNumber("その他費用"),
    対応車両: enumWithMessage(
      ["軽自動車", "コンパクトカー", "中型車", "ワンボックス", "大型車・SUV", "トラック・大型重量車両", "バイク"],
      "対応車両"
    ),
    識別: enumWithMessage(["機械式", "平置き", "立体"], "識別"),
    屋根: enumWithMessage(["あり", "なし"], "屋根").optional(),
    舗装: enumWithMessage(["コンクリート", "アスファルト", "砂利", "その他"], "舗装"),
    時間: z
      .string()
      .optional()
      .refine((val) => !val || /^(?:\d{2}:\d{2}[~～]\d{2}:\d{2}|24時間)$/.test(val), {
        message: "時間は 'HH:MM~HH:MM', 'HH:MM～HH:MM', '24時間' の形式で入力してください",
      }),
    貸与物: z.string().optional(),
    "高さ(mm)": positiveNumber("高さ(mm)").optional(),
    "長さ（mm）": positiveNumber("長さ（mm）").optional(),
    "車幅（mm）": positiveNumber("車幅（mm）").optional(),
    "地上高（mm）": positiveNumber("地上高（mm）").optional(),
    "タイヤ幅（mm）": positiveNumber("タイヤ幅（mm）").optional(),
    "重さ(kg)": positiveNumber("重さ(kg)").optional(),
    契約期間: positiveNumber("契約期間", 1).optional(),
    更新料: positiveNumber("更新料"),
    日割: enumWithMessage(
      ["全期間日割り可能", "全期間日割不可", "開始月のみ日割り可能", "終了月のみ日割り可能"],
      "日割"
    ),
    区画の備考: z.string().optional(),
    区画名: z.string().optional(),
    利用開始日: z
      .string()
      .optional()
      .refine((date) => !date || !isNaN(Date.parse(date)), { message: "利用開始日は有効な日付を指定してください" }),
    利用終了日: z
      .string()
      .optional()
      .refine((date) => !date || !isNaN(Date.parse(date)), { message: "利用終了日は有効な日付を指定してください" }),
    契約者: z.string().optional(),
    電話番号: z
      .string()
      .optional()
      .refine(
        (val) => {
          if (!val) return true;
          const replacedPhoneNumber = val.replace(/-/g, "");
          return /^(070|080|090)\d{8}$/.test(replacedPhoneNumber) || /^0\d{9}$/.test(replacedPhoneNumber);
        },
        {
          message: "電話番号は0から始まり9桁または10桁の数字である必要があります",
        }
      ),
  })
  .refine(
    (data) => {
      if (data.利用終了日 && data.利用開始日) {
        return dayjs(data.利用開始日).isBefore(dayjs(data.利用終了日));
      }
      return true;
    },
    { message: "利用終了日は利用開始日より後の日付にしてください" }
  )
  .refine(
    (data) => {
      if (data.利用開始日 && !data.区画名) {
        return false;
      }
      return true;
    },
    { message: "利用開始日を指定する場合は、区画名は必須です" }
  )
  .refine(
    (data) => {
      if (data.利用終了日 && !data.利用開始日) {
        return false;
      }
      return true;
    },
    { message: "利用終了日を指定する場合は、利用開始日も指定してください" }
  )
  .refine(
    (data) => {
      if (data.電話番号 && !data.契約者) {
        return false;
      }
      return true;
    },
    { message: "電話番号を入力する場合は、契約者も入力してください" }
  );

export { PropertyCsvSchema };
