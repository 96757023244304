import styled from "styled-components";

import TextForm from "components/TextForm";

const Label = styled.p`
  margin-bottom: 10px;
  font-size: 14px;
`;

const FlexCenter = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
`;

const MarginRight = styled.div`
  margin-right: 10px;
`;

const MarginRightFive = styled.div`
  margin-right: 5px;
`;

interface Props {
  onChange: (field: string, e: React.ChangeEvent<HTMLInputElement>) => void;
  priceStructure: PriceStructureData;
}

const MaxPriceBlock = ({ onChange, priceStructure }: Props) => {
  return (
    <div>
      <Label>入庫後最大料金</Label>
      <FlexCenter>
        <MarginRight>
          <FlexCenter>
            <TextForm
              type="text"
              width="50px"
              value={priceStructure["maxDurationHours"]}
              placeholder=""
              required
              onChange={(e) => onChange("maxDurationHours", e)}
              isError={false}
              errorMessage=""
              disabled={priceStructure.timeSlot1.startHour === "" || priceStructure.timeSlot1.startMinute === ""}
            />
            <MarginRightFive />
            <span>時間</span>
          </FlexCenter>
        </MarginRight>
        <MarginRight>
          <FlexCenter>
            <TextForm
              type="number"
              width="80px"
              value={priceStructure["maxPricePerDuration"]}
              placeholder=""
              required
              onChange={(e) => onChange("maxPricePerDuration", e)}
              isError={priceStructure["maxDurationHours"] !== null && priceStructure["maxPricePerDuration"] === null}
              errorMessage="入庫後最大時間を入力した場合、時間帯最大料金も入力してください"
              disabled={priceStructure.maxDurationHours === null}
            />
            <MarginRightFive />
            <span>円</span>
          </FlexCenter>
        </MarginRight>
      </FlexCenter>
    </div>
  );
};

export default MaxPriceBlock;
