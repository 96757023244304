import styled from "styled-components";

import color from "constants/color";
import MaxPriceBlock from "pages/PitPort/Property/components/MaxPriceBlock";
import TimePriceBlock from "pages/PitPort/Property/components/TimePriceBlock";

const Row = styled.div`
  display: flex;
  padding: 10px 10px 10px 0;
  gap: 10px;
`;

const Divider = styled.div`
  border-bottom: 1px solid ${color.border};
`;

interface Props {
  title: string;
  priceStructure: PriceStructureData;
  onChange: (field: string, e: React.ChangeEvent<HTMLInputElement>) => void;
  errors: {
    timeSlot1: {
      startHour: boolean;
      startMinute: boolean;
      price: boolean;
    };
    timeSlot2: {
      startHour: boolean;
      startMinute: boolean;
      price: boolean;
    };
  };
}

const DayPriceSetting = ({ title, priceStructure, onChange, errors }: Props) => {
  return (
    <div>
      <h4>{title}</h4>
      <Row>
        <TimePriceBlock
          onChange={onChange}
          priceStructure={priceStructure}
          fieldPrefix="timeSlot1"
          errors={errors.timeSlot1}
        />
        <TimePriceBlock
          onChange={onChange}
          priceStructure={priceStructure}
          fieldPrefix="timeSlot2"
          errors={errors.timeSlot2}
        />
        <MaxPriceBlock onChange={onChange} priceStructure={priceStructure} />
      </Row>
      <Divider />
    </div>
  );
};

export default DayPriceSetting;
